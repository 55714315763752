<template>
  <b-container v-touch:swipe.left="swipeLeft" v-touch:swipe.right="swipeRight">
    <b-row>
      <b-col cols="1"/>
        <b-col>
        <h2>App Funktionen</h2>
        </b-col>
    </b-row>
    <b-row>
      <b-col cols="1"/>
        <b-col cols="10">
        <img src="@/assets/illustrations/manwithphone.svg" class="illustration" alt="illustration" style="width: 100%; height: 100%; margin-top: 1.5rem; margin-bottom: 1.25rem;"/>
        </b-col>
      <b-col cols="1"/>
    </b-row>
    <br/>
    <b-row>
      <b-col cols="1"/>
        <b-col cols="10">
          <p style="margin-bottom: 10rem;">Das Fahrrad ist entsperrt. Im unteren Teil der Ansicht findest du deine Action Buttons.<br> Hier kannst du die gefahrene Zeit und andere Infos ansehen, das Bike sperren oder für max. 30 min pausieren.</p>
        </b-col> 
      <b-col cols="1"/>
    </b-row>
    <b-row class="fixed-bottom pageIndicatorPos">
      <b-col cols="2"/>
      <b-col cols="8">
        <PageIndicator route="appTutorial" :max="6" :pos="5"/>
      </b-col>
      <b-col cols="2"/>
    </b-row>
     <b-row>
      <NextButton text="Weiter" next @click="$router.push('/apptutorial/6')" />
    </b-row>
  </b-container>
</template>

<script>
import PageIndicator from '../components/PageIndicator';
import NextButton from '../components/NextButton';
  export default {
    name: 'AppTutorial5',
    components: {
      PageIndicator,
      NextButton,
    },
    methods: {
      swipeLeft() {
        this.$router.push('/appTutorial/6')
      },
      swipeRight() {
        this.$router.push('/appTutorial/4')
      }
    }
  }
</script>

<style scoped>
.illustration {
  pointer-events: none;
}
</style>