<template>
  <div :class="disabledClass" @click="emitEvent('click')">
    {{text}}
    <b-icon v-if="next" icon="chevron-right" />
  </div>
</template>

<script>
export default {
  name: 'NextButton',
  props: {
    text: String,
    next: Boolean,
    disabled: Boolean,
  },
  computed: {
    disabledClass() {
      return this.disabled ? 'nextButtonDisabled' : 'nextButton';
    }
  },
  methods: {
    emitEvent(e) {
      if (!this.disabled) this.$emit(e);
    }
  }
}
</script>

<style scoped>
.nextButton {
  background-color: var(--interaction-color);
  color: var(--white-color);
  z-index: 10;
  font-size: 14pt;
  position: fixed;
  width: 8.2rem;
  height: 3rem;
  line-height: 3rem;
  right: 0rem;
  bottom: 2.1037rem;
  border-radius: 24px 0 0 1.5rem;
  text-align: center;
  cursor: pointer;
  transition: 0.25s;
  
}
.nextButtonDisabled {
  background-color: var(--secondary-text-icon-color);
  color: var(--white-color);
  z-index: 10;
  font-size: 14pt;
  position: fixed;
  width: 8.2rem;
  height: 3rem;
  line-height: 3rem;
  right: 0rem;
  bottom: 2.1037rem;
  border-radius: 24px 0 0 1.5rem;
  text-align: center;
  cursor: pointer;
  transition: 0.25s;
}
</style>