<template>
  <div class="pageIndicator-wrapper" ref="pageIndicatorWrapper">
    <div
      class="pageIndicator-content"
      :class="{ 'pageIndicator-active': n === pos }"
      :style="`width: ${indicatorWidth}px;`"
      v-for="n in max"
      :key="n"
      @click="$router.push(`/${route}/${n}`)"
    />
  </div>
</template>

<script>
export default {
  name: 'PageIndicator',
  data() {
    return {
      loaded: false,
    }
  },
  props: {
    route: String,
    max: Number,
    pos: Number
  },
  computed: {
    indicatorWidth() {
      if (!this.loaded) return 10;
      // calculates the indicator widths based on the screen size
      return this.$refs.pageIndicatorWrapper.clientWidth / this.max;
    }
  },
  mounted() {
    this.loaded = true;
  }

}
</script>

<style scoped>

.pageIndicator-wrapper {
  width: 100%;
  display: flex;
}

.pageIndicator-content {
  cursor: pointer;
  background-color: var(--secondary-text-icon-color);
  height: 0.3125rem;
  margin: 0.25rem;
  border-radius: 0.3125rem;
}

.pageIndicator-active {
  background-color: var(--visual-information-color);
}

</style>